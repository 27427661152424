<template>
  <div>
    <b-overlay :show="loading">
      <div class="w-100 d-flex justify-content">
        <b-button variant="warning" @click="$router.go(-1)">
          {{$t('message.back')}}
        </b-button>
      </div>

      <ValidationObserver ref="refFormObserver">
        <b-card class="mt-2" v-if="!$route.params.id && !$route.params.tourId">
          <!-- Client  -->
          <b-row>
            <b-col md="12">
              <h4>
                {{$t('message.client_text')}}
              </h4>
            </b-col>
            <!-- Client firstNmae  -->
            <b-col md="12">
              <ValidationProvider #default="validationContext" :name="$t('data.firstNmae')" rules="required">
                <div class="wrapper">
                  <p class="wrapper-title">
                    {{$t('data.firstNmae')}}
                  </p>
                  <div class="wrapper-form">
                    <b-icon icon="person"></b-icon>
                    <input class="form-control" v-model="first_name" @input="toUpperCaseFirstname" />
                  </div>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </b-col>
            <!-- Client lastName  -->
            <b-col md="12">
              <ValidationProvider #default="validationContext" :name="$t('data.lastName')" rules="required">
                <div class="wrapper">
                  <p class="wrapper-title">
                    {{$t('data.lastName')}}
                  </p>
                  <div class="wrapper-form">
                    <b-icon icon="person"></b-icon>
                    <input class="form-control" v-model="last_name" @input="toUpperCaseLastname" />
                  </div>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </b-col>
            <!-- Client birthday  -->
            <b-col md="4">
              <ValidationProvider #default="validationContext" :name="$t('data.date_birthday')" rules="required">
                <div class="wrapper">
                  <p class="wrapper-title">
                    {{$t('data.date_birthday')}}
                  </p>
                  <div class="wrapper-form">
                    <!-- <b-icon icon="calendar2-check"></b-icon> -->
                    <input type="date" class="form-control" v-model="birthdate">
                  </div>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </b-col>
            <!-- Client Passport Data  -->
            <b-col md="4">
              <ValidationProvider #default="validationContext" :name="$t('data.passport')" rules="required">
                <div class="wrapper">
                  <p class="wrapper-title">
                    {{$t('data.passport')}}
                  </p>
                  <div class="wrapper-form">
                    <b-icon icon="book"></b-icon>
                    <b-form-input v-model="passport_serial" placeholder="AA" v-mask="'AA'" style="width: 50px;"
                      type="text" @input="toLoware"></b-form-input>
                    <b-form-input v-model="passport_number" placeholder="1234567" v-mask="'#######'" type="text">
                    </b-form-input>
                  </div>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="4">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.file')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="file-earmark"></b-icon>
                  <input type="file" class="form-control" @change="fileChange">
                </div>
              </div>
            </b-col>

            <b-col md="4">
              <ValidationProvider #default="validationContext" :name="$t('data.given_date')" rules="required">
                <div class="wrapper">
                  <p class="wrapper-title">
                    {{$t('data.given_date')}}
                  </p>
                  <div class="wrapper-form">
                    <!-- <b-icon icon="calendar2-check"></b-icon> -->
                    <input type="date" class="form-control" v-model="given_date">
                  </div>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="4">
              <ValidationProvider #default="validationContext" :name="$t('data.expire_date')" rules="required">
                <div class="wrapper">
                  <p class="wrapper-title">
                    {{$t('data.expire_date')}}
                  </p>
                  <div class="wrapper-form">
                    <!-- <b-icon icon="calendar2-check"></b-icon> -->
                    <input type="date" class="form-control" v-model="expire_date">
                  </div>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="4">
              <ValidationProvider #default="validationContext" :name="$t('data.mobile_number')" rules="required">
                <div class="wrapper">
                  <p class="wrapper-title">
                    {{$t('data.mobile_number')}}
                  </p>
                  <div class="wrapper-form">
                    <b-icon icon="telephone"></b-icon>
                    <b-form-input v-model="number1" v-mask="'############'" type="text"></b-form-input>
                  </div>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </b-col>
            <!-- Client mobile number additional -->
            <b-col md="4">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.mobile_number_additional')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="telephone"></b-icon>
                  <b-form-input v-model="number2" v-mask="'############'" type="text"></b-form-input>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>


        <div class="d-flex justify-content-end w-100 py-2">
          <b-button class="ml-2" variant="primary" @click="handleAdd" :disabled="disabledItem">
            {{$t('message.Add')}}
          </b-button>
        </div>
      </ValidationObserver>
    </b-overlay>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations';
  import {
    addClients,
    editClients,
    getClientsById
  } from '@/api/clients/clients'
  import moment from 'moment'

  export default {
    name: "AddClients-router",
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        loading: false,
        required,
        alphaNum,
        number1: null,
        number2: null,
        first_name: '',
        last_name: '',
        birthdate: null,
        passport_serial: '',
        passport_number: '',
        given_date: null,
        expire_date: null,
        file: null,
        id: null,
        form: {
          id: null,
          first_name: '',
          last_name: '',
          birthdate: null,
          passport_serial: '',
          passport_number: '',
          contacts: [{
              number: ''
            },
            {
              number: ''
            }
          ],
          given_date: null,
          expire_date: null,
          disabledItem: false,
        }
      }
    },
    created() {
      if (this.$route.params.clientPutId) {
        return this.id = this.$route.params.clientPutId
      }
    },
    methods: {
      fileChange(e) {
        this.file = e.target.files[0]
      },
      toLoware() {
        this.passport_serial = this.passport_serial.toUpperCase();
      },
      handleAdd() {
        const isValid = this.$refs.refFormObserver.validate();
        this.disabledItem = true;
        this.loading = true

        setTimeout(() => {
          this.loading = false
          this.disabledItem = false;

        }, 1000)
        if (isValid) {
          this.form.first_name = this.first_name;
          this.form.last_name = this.last_name;
          this.form.passport_serial = this.passport_serial;
          this.form.passport_number = this.passport_number;
          this.form.contacts = [];
          this.form.contacts = [{
            number: this.number1
          }, {
            number: this.number2
          }];
          this.form.contacts = JSON.stringify(this.form.contacts);
          this.form.given_date = moment(this.given_date).format('YYYY-MM-DD');
          this.form.expire_date = moment(this.expire_date).format('YYYY-MM-DD');
          this.form.birthdate = moment(this.birthdate).format('YYYY-MM-DD');

          if (this.$route.params.clientPutId) {
            let fd = new FormData();
            fd.append('id', this.$route.params.clientPutId)
            fd.append('first_name', this.form.first_name)
            fd.append('last_name', this.form.last_name)
            fd.append('passport_serial', this.form.passport_serial)
            fd.append('passport_number', this.form.passport_number)
            fd.append('contacts', this.form.contacts)
            fd.append('given_date', this.form.given_date)
            fd.append('expire_date', this.form.expire_date)
            fd.append('birthdate', this.form.birthdate)
            fd.append('file', this.file);
            fd.append('_method', 'put');
            editClients(fd).then(() => {
              this.$router.go(-1);

            })
          } else {
            let fd = new FormData();
            fd.append('first_name', this.form.first_name)
            fd.append('last_name', this.form.last_name)
            fd.append('passport_serial', this.form.passport_serial)
            fd.append('passport_number', this.form.passport_number)
            fd.append('contacts', this.form.contacts)
            fd.append('given_date', this.form.given_date)
            fd.append('expire_date', this.form.expire_date)
            fd.append('birthdate', this.form.birthdate)
            fd.append('file', this.file);
            addClients(fd).then(() => {
              this.$router.go(-1);
            })
          }
        }
      },
      toUpperCaseFirstname() {
        this.first_name = this.first_name.toUpperCase();
      },
      toUpperCaseLastname() {
        this.last_name = this.last_name.toUpperCase();
      },
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            getClientsById(this.id).then(response => {
              response.data.data.contacts = JSON.parse(response.data.data.contacts)
              let res = response.data.data;
              if (res.contacts.length > 1) {
                this.number1 = parseInt(res.contacts[0].number);
                this.number2 = parseInt(res.contacts[1].number);
              } else {
                this.number1 = parseInt(res.contacts.number)
              }
              this.first_name = res.first_name
              this.last_name = res.last_name
              this.birthdate = moment(res.birthdate).format('YYYY-MM-DD')
              this.passport_serial = res.passport_serial
              this.passport_number = res.passport_number
              this.given_date = moment(res.given_date).format('YYYY-MM-DD')
              this.expire_date = moment(res.expire_date).format('YYYY-MM-DD')
            })
          }
        }
      }
    }
  }
</script>